import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui';
// import Login from '../components/Login.vue'
// import Home from '../components/Home.vue'
// import Welcome from '../components/Welcome.vue'
// import Users from '../views/Users.vue'
// import UserRole from '../views/UserRole.vue'
// import Equipment from '../views/Equipment.vue'

// 路由懒加载，对路径进行分组打包

const Login = () =>
    import ( /* webpackChunkName: "login_home_welcome" */ '../components/Login.vue')
const Home = () =>
    import ( /* webpackChunkName: "login_home_welcome" */ '../components/Home.vue')
const Welcome = () =>
    import ( /* webpackChunkName: "login_home_welcome" */ '../components/Welcome.vue')

const ChangePassword = () =>
    import ( /* webpackChunkName: "login_home_welcome" */ '../components/ChangePassword.vue')

const Users = () =>
    import ( /* webpackChunkName: "admin_management" */ '../views/Users.vue')
const UserRole = () =>
    import ( /* webpackChunkName: "admin_management" */ '../views/UserRole.vue')

// const Equipment = () =>
//     import ( /* webpackChunkName: "equipment" */ '../views/Equipment.vue')

// const Customers = () =>
//     import ( /* webpackChunkName: "base_info" */ '../views/Customers.vue')
// const Product = () =>
//     import ( /* webpackChunkName: "base_info" */ '../views/Product.vue')
// const ProductRelated = () =>
//     import ( /* webpackChunkName: "base_info" */ '../views/ProductRelated.vue')
// const ProductPrice = () =>
//     import ( /* webpackChunkName: "base_info" */ '../views/ProductPrice.vue')
// const Invoice = () =>
//     import ( /* webpackChunkName: "busy_info" */ '../views/Invoice.vue')
// const NoInbound = () =>
//     import ( /* webpackChunkName: "busy_info" */ '../views/NoInbound.vue')
// const CheckIn = () =>
//     import ( /* webpackChunkName: "check_info" */ '../views/CheckIn.vue')
// const CheckOut = () =>
//     import ( /* webpackChunkName: "check_info" */ '../views/CheckOut.vue')
// const GetInbound = () =>
//     import ( /* webpackChunkName: "report_info" */ '../views/GetInbound.vue')
// const InboundReport = () =>
//     import ( /* webpackChunkName: "report_info" */ '../views/InboundReport.vue')
// const OutboundReport = () =>
//     import ( /* webpackChunkName: "report_info" */ '../views/OutboundReport.vue')
// const ReboundReport = () =>
//     import ( /* webpackChunkName: "report_info" */ '../views/ReboundReport.vue')
// const StockReport = () =>
//     import ( /* webpackChunkName: "report_info" */ '../views/StockReport.vue')
// const TraceByCode = () =>
//     import ( /* webpackChunkName: "trace_info" */ '../views/TraceByCode.vue')
// const Warehouse = () =>
//     import ( /* webpackChunkName: "base_info" */ '../views/Warehouse.vue')
// const UsersSub = () =>
//     import ( /* webpackChunkName: "base_info" */ '../views/UsersSub.vue')
// const SalePerson = () =>
//     import ( /* webpackChunkName: "base_info" */ '../views/SalePerson.vue')
// const ProductReport = () =>
//     import ( /* webpackChunkName: "report_info" */ '../views/ProductReport.vue')

const Company = () =>
    import ( /* webpackChunkName: "admin_management" */ '../views/Company.vue')
const Department = () =>
    import ( /* webpackChunkName: "admin_management" */ '../views/Department.vue')
const Staff = () =>
    import ( /* webpackChunkName: "admin_management" */ '../views/Staff.vue')
const UsersSub = () =>
    import ( /* webpackChunkName: "admin_management" */ '../views/UsersSub.vue')
const Industry = () =>
    import ( /* webpackChunkName: "admin_management" */ '../views/Industry.vue')

Vue.use(VueRouter)

const routes = [
    { path: '/', redirect: '/login' },
    { path: '/login', component: Login },
    {
        path: '/home',
        component: Home,
        redirect: '/welcome',
        children: [
            { path: '/welcome', component: Welcome },
            { path: '/users', component: Users },
            { path: '/userrole', component: UserRole },

            { path: '/company', component: Company },
            { path: '/department', component: Department },
            { path: '/staff', component: Staff },
            { path: '/usersub', component: UsersSub },
            { path: '/industry', component: Industry },
        ]
    }
]

const router = new VueRouter({
        mode: 'hash', //history
        base: process.env.BASE_URL,
        routes
    })
    // 挂载路由导航守卫 beforeEach,to:想去哪个路由来，from：从哪个路径来,next是一个函数，表示放行
router.beforeEach((to, from, next) => {
    if (to.path === '/login') return next();
    // 获取token
    const tokenStr = window.sessionStorage.getItem('token');
    if (!tokenStr) {
        Message.error("未取得有效token信息");
        return next('/login');
    }
    // token有值，则放行

    next();
})
export default router