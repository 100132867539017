import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './plugins/element.js'
import 'default-passive-events'
import qs from 'qs'
// 导入全局样式表
import './assets/css/global.css'
import './assets/iconfont/iconfont.css'
import axios from 'axios'
// import 'element-ui/lib/theme-chalk/index.css'
import global_ from './components/Global.vue'
import x2js from 'x2js' //xml数据处理插件
import md5 from 'js-md5'

// 全局挂载axios,给全局变量$http
Vue.prototype.$http = axios
Vue.prototype.$qs = qs
Vue.prototype.GLOBAL = global_
Vue.prototype.$x2js = new x2js() //全局挂载到vue原型上
Vue.prototype.$md5 = md5


// 导入NProgress进度条
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'

// 配置请求的根路径
// http://localhost:2781/api/   http://test.2szs.cn:83/api/  https://crm.2szs.cn:13443
axios.defaults.baseURL = 'https://crm.2szs.cn:13443/api/'
    // 增加axios拦截器,config为请求的对象
axios.interceptors.request.use(config => {
    NProgress.start();
    // console.log(config);
    //encodeURIComponent('https://www.baidu.com/#/rejister.........')
    let yyyy = new Date().getFullYear();　　
    let mm = new Date().getMonth() + 1;　　
    let dd = new Date().getDate();　　
    let hh = new Date().getHours();　　
    let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();　　
    let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
    var gettime = yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;

    var str = window.sessionStorage.getItem('userCode') + ',' +
        window.sessionStorage.getItem('userName') + ',' +
        window.sessionStorage.getItem('companyCode') + ',' +
        gettime + ',' +
        md5(window.sessionStorage.getItem('userCode') + 'qyy2022' + gettime) + "," +
        window.sessionStorage.getItem('isSale') + ',qyy2022';
    config.headers.token = encodeURIComponent(str);
    //   最后必须return config 固定写法
    return config;
})
axios.interceptors.response.use(config => {
    NProgress.done();
    return config;
})




Vue.config.productionTip = false
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')